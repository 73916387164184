<template>
  <section id="reports">
    <b-card>
      <div class="row pb-1 d-flex justify-content-between">
        <div class="col-sm-6">
          <label class="title"><feather-icon icon="BarChart2Icon" size="18" class="align-text-top" /> Reportes</label>
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
          <b-button variant="warning" class="mr-2" @click="createReport" :disabled="loading.first"><feather-icon
              icon="PlusIcon" /> Programar reporte</b-button>
        </div>
      </div>
      <filter-swapper id="reports_swapper" :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :key="keyFormRender" class="mb-2" :fields="fields" :buttonSend="buttonSend" :form.sync="formFilter"
            @send="filterList" containerButtonsClass="col-sm-12 col-md-2 mt-2">
            <template #buttons>
              <b-button variant="outline-light" class="ml-2" v-b-tooltip.hover title="Limpiar filtros"
                @click="cleanFilter"><feather-icon icon="RefreshCwIcon" /></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <b-skeleton v-show="loading.first" type="input" class="mt-2 mb-2 spacing-label-field" height="40px" />
      <table-render v-show="!loading.first" id="listReports" :actions="actions" :schema="schema" :rows="rows"
        :loading="loading.reports">
        <template #active="scope">
          <!-- <b-form-checkbox v-model="scope.rowdata.active" @change="changeStatus(scope.rowdata)" /> -->

          <label class="switch">
            <input type="checkbox" @click="changeStatus(scope.rowdata)" v-model="scope.rowdata.active">
            <span class="slider round"></span>
          </label>
          <!-- <label v-else class="switch"><input type="checkbox" @click="changeStatus(scope.rowdata)" v-model="scope.rowdata.active"><span
              class="slider round"></span></label> -->
          <!-- <label>{{scope.rowdata.active}}</label> -->
        </template>
      </table-render>
      <pagination v-show="!loading.first" :pagination="pagination" :noDigits="true" :showSize="true" />
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table :rows="10" :columns="schema.length || 10" :table-props="{}" />
      </div>
    </b-card>
    <modal-report-logs ref="modalReportLogs" :logs.sync="currentReportLogs"></modal-report-logs>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import ReportsService from './reports.service'
import ModalReportLogs from './ModalReportLogs.vue'

export default {
  name: 'automatic-reports',
  components: { ModalReportLogs },
  data() {
    return {
      buttons: [],
      buttonSend: { icon: 'SearchIcon', color: 'warning', title: 'Filtrar' },
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      loading: {
        first: true,
        reports: true,
        shippers: true,
        organizations: true
      },
      isSwitched: true,
      actions: [],
      schema: [],
      fields: [{
        fieldType: 'FieldSelect',
        name: 'type_email_report',
        label: 'Tipo de reporte',
        placeholder: 'Tipo de reporte',
        options: this.typeEmailReports,
        disabled: true
      }],
      shippers: [],
      all_shippers: [],
      organizations: [],
      rows: [],
      shipper_id: null,
      organization_id: null,
      timezone: null,
      pagination: { page: 1, limit: 20, total: 0, loading: false },
      showData: false,
      reportsService: new ReportsService(this),
      keyFormRender: 0,
      formFilter: {},
      payloadFilter: {},
      currentReportLogs: null
    }
  },
  watch: {
    'pagination.page'() {
      this.getFullData()
    },
    'pagination.limit'() {
      this.getFullData()
    },
    mySession() {
      if (this.mySession.id) this.initializeData()
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  mounted() {
    if (this.mySession.id) this.initializeData()
  },
  methods: {
    initializeData() {
      this.shipper_id = this.$session.get('cas_user').shipper?.id
      this.organization_id = this.$session.get('cas_user').organization?.id
      this.timezone = this.$session.get('cas_user').timezone
      this.schema = [
        { label: 'Nombre', sortable: true, key: 'name' },
        { label: 'Tipo', sortable: true, key: 'type_email_report.name' },
        { label: 'Días', sortable: true, key: 'days_name' },
        { label: 'Periocidad', sortable: true, key: 'recurrence_name' },
        { label: 'Estado', sortable: true, key: 'active', style: { width: '20px' }, useSlot: true },
        { label: 'Acciones', sortable: true, key: 'actions', style: { width: '10%' }, class: ['text-center'] }
      ]
      this.actions = [
        { action: id => this.updateReport(id), icon: 'Edit2Icon', color: 'primary', text: 'Editar reporte' },
        { action: id => this.deleteReportModal(id), icon: 'Trash2Icon', color: 'danger', text: 'Eliminar reporte' },
        { action: id => this.sendReportModal(id), icon: 'MailIcon', color: 'primary', text: 'Envíar reporte' },
        { action: id => this.reportLogsModal(id), icon: 'FileTextIcon', color: 'primary', text: 'ver registros del reporte' }
      ]

      if (['admin', 'superadmin', 'marketplace'].includes(this.$session.get('cas_user').role)) {
        // se agrega la columna de empresa
        this.schema.unshift({ label: 'Empresa', sortable: true, key: 'shipper_name' })

        // se agrega el filtro de empresa
        this.fields.unshift({
          fieldType: 'FieldSelect',
          name: 'shipper',
          label: 'Empresa',
          placeholder: 'Nombre de la empresa',
          searchOnType: { fx: this.searchShipper, nChars: 3, debounce: 600 },
          options: this.shippers
        })
        
        if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
          // se agrega la columna de organización
          this.schema.unshift({ label: 'Marketplace', sortable: true, key: 'organization_name' })
          // se agrega el filtro de organización
          this.fields.unshift({
            fieldType: 'FieldSelect',
            name: 'organization',
            label: 'Marketplace',
            placeholder: 'Nombre del marketplace',
            change: this.changeOrganization,
            searchOnType: { fx: this.searchOrganization, nChars: 3, debounce: 600 },
            options: this.organizations
          })
        }
      }

      // se obtienen los reportes
      this.getFullData()
      // se obtienen los tipos de reportes
      this.getTypes()
    },
    filterList(form) {
      this.payloadFilter = {}
      if (form.organization && form.organization.id) this.payloadFilter.organization = form.organization.id
      if (form.shipper && form.shipper.id) this.payloadFilter.shipper = form.shipper.id
      if (form.type_email_report && form.type_email_report.id) this.payloadFilter.type_email_report = form.type_email_report.id
      this.pagination.page = 1
      this.getFullData()
    },
    cleanFilter() {
      this.shippers = []
      this.organizations = []
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        this.fields.find(el => el.name === 'shipper').options = this.shippers
        this.fields.find(el => el.name === 'organization').options = this.organizations
      } else if (this.$session.get('cas_user').role === 'marketplace') {
        this.fields.find(el => el.name === 'shipper').options = this.shippers
      }
      this.formFilter = {}
      this.keyFormRender++
      this.filterList(this.formFilter)
    },
    getFullData() {
      this.loading.reports = true
      const queryParams = { limit: this.pagination.limit, page: this.pagination.page }
      if (this.payloadFilter.organization) {
        queryParams.organization_id = this.payloadFilter.organization
      } else if (this.$session.get('cas_user').role === 'marketplace' && this.$session.get('cas_user').organization?.id) {
        queryParams.organization_id = this.$session.get('cas_user').organization?.id
      }
      if (this.payloadFilter.shipper) {
        queryParams.shipper_id = this.payloadFilter.shipper
      } else if (this.$session.get('cas_user').role === 'ecommerce' && this.$session.get('cas_user').shipper?.id) {
        queryParams.shipper_id = this.$session.get('cas_user').shipper?.id
      }
      if (this.payloadFilter.type_email_report) queryParams.type_email_report_id = this.payloadFilter.type_email_report
      this.reportsService.callService('getReports', queryParams, queryParams)
        .then(response => {
          if (response?.data) {
            this.setReportRows(response.data)
          } else {
            this.setReportRows([])
          }
          this.loading.first = false
          this.loading.reports = false
        }).catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-problema-cargar-datos', { code: err }))
        }).finally(() => {
          this.loading.first = false
          this.loading.reports = false
        })
    },
    getTypes() {
      this.reportsService.callService('getTypesReport', {}, {})
        .then(response => {
          this.types = response.data.map(type => {
            return { id: type.id, name: type.name, text: type.name }
          })
          this.fields.find(el => el.name === 'type_email_report').options = this.types
          this.fields.find(el => el.name === 'type_email_report').disabled = false
        }).catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-problema-cargar-datos', { code: err }))
          this.fields.find(el => el.name === 'type_email_report').disabled = false
        })
    },
    getOrganizations(initial_loading = false) {
      const queryParams = { paginate_by: 9999, page: 1 }
      this.reportsService.callService('getOrganizations', queryParams, {})
        .then(response => {
          this.organizations = response.data.map(el => ({ ...el, text: `${el.id} - ${el.name}` }))
          this.fields.find(el => el.name === 'organization').options = this.organizations
          this.fields.find(el => el.name === 'organization').disabled = false
        }).catch(err => {
          console.error(err)
          this.$alert('No existen organizaciones')
          this.fields.find(el => el.name === 'organization').disabled = false
        })
    },
    getShippers(organization_id = null) {
      const queryParams = { paginate_by: 9999, page: 1, organization_id }
      const pathParams = organization_id ? { organization_id } : {}
      this.reportsService.callService('getShippers', queryParams, pathParams)
        .then(response => {
          this.shippers = response.data.map(el => ({ ...el, text: `${el.id} - ${el.name1}` }))
          this.fields.find(el => el.name === 'shipper').options = this.shippers
        }).catch(err => {
          console.error(err)
          this.$alert('No existen empresas asociadas a la organización')
        })
    },
    setReportRows(data) {
      this.rows = data
    },

    createReport() {
      // window.open('/automatic-reports/create', '_blank')
      this.$router.push({ name: 'automatic-reports-create' })
    },

    updateReport(id) {
      this.$router.push({ name: 'automatic-reports-update', params: { id } })
    },
    deleteReportModal(id) {
      this.$yesno('¿Está seguro que desea eliminar este reporte?', () => this.deleteReport(id))
    },
    sendReportModal(id) {
      this.$yesno('¿Está seguro que desea envíar este reporte?', () => this.sendReport(id))
    },
    reportLogsModal(id) {
      this.$refs.modalReportLogs.loading = true
      const params = { id }
      this.currentReportLogs = []
      this.reportsService.callService('getReportLogs', null, params)
        .then(resp => {
          this.$bvModal.show('modalReportLogs')
          if (resp?.data) {
            this.currentReportLogs = resp.data
          }
        })
        .catch(err => {
          console.error(err)
          this.$alert('Ocurrió un problema al cargar los logs del reporte')
        })
    },
    deleteReport(id) {
      this.reportsService.callService('deleteReport', {}, { id })
        .then(response => {
          this.rows = this.rows.filter(e => e.id !== id)
          this.$success('Se ha eliminado el reporte')
        }).catch(err => {
          console.error(err)
          this.$alert('No se pudo eliminar el reporte')
        })
    },
    sendReport(id) {
      this.reportsService.callService('sendReport', { 'report_id': id })
        .then(response => {
          this.$success('Su reporte ha sido procesado, le llegará el correo en unos instantes')
        }).catch(err => {
          console.error(err)
          this.$alert('No se pudo enviar el reporte')
        })
    },
    changeStatus(row) {
      const active = !row.active ? 1 : 0
      this.reportsService.callService('updatePatchReport', { active }, { 'id': row.id })
        .then(response => {
          this.$success(`Tu reporte se ha ${active ? 'activado' : 'desactivado'} correctamente`)
        }).catch(err => {
          row.active = !row.active
          console.error(err)
          this.$alert('No se pudo actualizar el estado del reporte')
        })
    },
    changeOrganization(field, value) {
      this.getShippers(value.id)
    },
    searchOrganization(name) {
      const queryParams = { paginate_by: 9999, page: 1, name }
      return this.reportsService.callService('getOrganizations', queryParams, {})
        .then(response => {
          return this.organizations = response.data.map(el => ({ ...el, text: el.name }))
        }).catch(err => {
          console.error(err)
          this.$alert('No existen organizaciones')
          return []
        })
    },
    searchShipper(name) {
      let queryParams = { paginate_by: 9999, page: 1, name }
      if (this.formFilter.organization) {
        queryParams = { ...queryParams, organization_id: this.formFilter.organization.id }
      }
      return this.reportsService.callService('getShippers', queryParams, {})
        .then(response => {
          return response.data.map(el => ({ ...el, text: el.name1 }))
        }).catch(err => {
          console.error(err)
          this.$alert('No existen empresas asociadas a la organización')
          return []
        })
    }
  }
}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.title {
  font-size: 1.4rem;
  font-weight: 500;
  color: $primary
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 3.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #f18805;
}

input:focus+.slider {
  box-shadow: 0 0 1px #f18805;
}

input:checked+.slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
