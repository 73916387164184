import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  { name: 'getReports', url: `${environment.dementorsApiUrl}/reports`, method: 'get' },
  { name: 'getReport', url: `${environment.dementorsApiUrl}/reports/{id}`, method: 'get' },
  { name: 'getStages', url: `${environment.dementorsApiUrl}/stages`, method: 'get' },
  { name: 'getFields', url: `${environment.dementorsApiUrl}/fields`, method: 'get' },
  { name: 'updateReport', url: `${environment.dementorsApiUrl}/reports/{id}`, method: 'put' },
  { name: 'updatePatchReport', url: `${environment.dementorsApiUrl}/reports/{id}`, method: 'patch' },
  { name: 'createReport', url: `${environment.dementorsApiUrl}/reports`, method: 'post' },
  { name: 'deleteReport', url: `${environment.dementorsApiUrl}/reports/{id}`, method: 'delete' },
  { name: 'sendReport', url: `${environment.dementorsApiUrl}/send-report`, method: 'post' },
  { name: 'getTypesReport', url: `${environment.dementorsApiUrl}/type-email-reports`, method: 'get' },
  { name: 'getReportLogs', url: `${environment.dementorsApiUrl}/reports/{id}/logs`, method: 'get' },
  { name: 'getShippers', url: `${environment.dubaiApiUrl}/shippers`, method: 'get'},
  { name: 'getOrganizations', url: `${environment.dubaiApiUrl}/organizations`, method: 'get'},
  { name: 'getCarriers', url: `${environment.newyorkApiUrl}/v2/coverage/carriers`, method: 'get' },
  { name: 'getCarriersByShipper', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/carriers`, method: 'get' },
  { name: 'getCarriersByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/carriers`, method: 'get' },
  { name: 'getShippersByOrganization', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers`, method: 'get'},

  { name: 'getAllUsers', url: `${environment.apiUrl}/users`, method: 'get'}
]

export default class ReportsService {
  constructor(context) {
    this.context = context
  }
  async callService(name, queryParams = {}, params = {}, config = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params, config)
  }

  async callMultipleServices(callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }
  setEnvironment() {
    // return environment.platformUrl
    return 'http://localhost:8080'
  }
}