<template>
  <div>
    <b-modal id="modalReportLogs" title="Registros del reporte" ok-variant="outline-success" ok-only ok-title="Cerrar"
      centered>
      <b-alert class="p-1" variant="primary" show>
        <div class="d-flex justify-content-center align-items-center">
          <feather-icon size="30" class="mr-50" icon="InfoIcon" />
          <span>Envíame muestra los registros de los últimos 15 envíos del reporte.</span>
        </div>
      </b-alert>
      <h5 class="mt-1">Registro de envíos de correo</h5>
      <table-render :schema="schema" :rows="rowsLogs" :actions="actionsLogs">
        <template #status="scope">
          <label :class="scope.rowdata.status == 1 ? 'text-success' : 'text-danger'">{{ scope.rowdata.status == 1 ? 'correcto' : 'error' }} <feather-icon v-if="scope.rowdata.status == 0" v-b-tooltip.hover.top="scope.rowdata.error_message" icon="InfoIcon" size="15" class="warning filterInput-XIcon"/> </label>
        </template>
        <template #execution_type="scope">
          <label>{{ scope.rowdata.execution_type == "automatic" || scope.rowdata.execution_type == null ? "automático" : "manual" }}</label>
        </template>
        <template #file_url="scope">
          <!-- link new window blank -->
          <a v-if="scope.rowdata.file_url" :href="scope.rowdata.file_url" target="_blank" v-b-tooltip.hover.top="'Descargar archivo'" class="text-primar'">
            <feather-icon icon="DownloadIcon" size="15"></feather-icon> 
          </a>
          <a v-else v-b-tooltip.hover.top="'No se generó archivo'" class="text-muted">
            <feather-icon icon="SlashIcon" size="15"></feather-icon>
          </a>
        </template>
      </table-render>
    </b-modal>
  </div>
</template>
  
<script>
export default {
  name: 'modal-report-logs',
  props: ['logs'],
  data() {
    return {
      rowsLogs: [],
      actionsLogs: [],
      schema: []
    }
  },
  watch: {
    logs() {
      this.rowsLogs = this.logs
    }
  },
  mounted() {
    this.initializeData()
  },
  methods: {
    initializeData() {
      this.schema = [
        { label: 'fecha', sortable: true, key: 'created_at' },
        { label: 'Ejecución', sortable: true, key: 'execution_type', useSlot: true },
        { label: 'Estado', sortable: true, key: 'status', useSlot: true },
        { label: 'Archivo', sortable: true, key: 'file_url', useSlot: true, class: ['text-center'] }
      ]
    }
  }
}
</script>
  